import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IPagingFilter } from 'src/app/shared/models/helper/paging-filter.model';
import { IProductLocaleInfo } from 'src/app/shared/models/product/product-locale-info.model';
import { IResponse } from 'src/app/shared/models/response/response.model';
import { IProductService } from '../../../shared/interface/product/product-interface.service';
import { IProduct } from '../../../shared/models/product/product.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;
@Injectable({
    providedIn: 'root',
})
export class ProductService extends GenericService<IProduct> implements IProductService {
    datasProduct: any;

    constructor(httpClient: HttpClient) {
        super(httpClient, "product");
    }

    createList(entities: IProduct[]): Observable<HttpResponse<IProduct[]>> {
        const headers = { 'Content-Type': 'application/json' };
        return this.httpClient
            .post<IProduct[]>(`${this.resourceUrl}/product-list`, entities, {
                headers: headers,
                observe: 'response',
            })
            .pipe(
                map((response: HttpResponse<IProduct[]>) => {
                    return response;
                })
            );
    }

    createDemoProduct(digitalId?: string): Observable<HttpResponse<any>> {
        const headers = { 'Content-Type': 'application/json' };
        let param: HttpParams = new HttpParams();
        param = param.set("digitalId", digitalId);
        return this.httpClient
            .post<any>(`${this.resourceUrl}/demo`, null, { params: param })
            .pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                })
            );
    }

    updateRangeFromFilters(newRangeId: number, filters: IPagingFilter[]): Observable<HttpResponse<any>> {
        const headers = { 'Content-Type': 'application/json' };
        return this.httpClient
            .put<any>(`${this.resourceUrl}/range`, filters, {
                headers: headers,
                observe: 'response',
                params: { newRangeId: newRangeId }
            })
            .pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                })
            );
    }

    getDetailFromDigitalId(digitalId: number) {
        return this.httpClient.get<IProduct>(`${this.resourceUrl}/get-from-digital-id/${digitalId}`);
    }

    getDetailFromSku(sku: string) {
        return this.httpClient.get<IProduct>(`${this.resourceUrl}/get-from-sku/${sku}`);
    }

    getProductLocales(id: number): Observable<IProductLocaleInfo> {
        return this.httpClient.get<IResponse<IProductLocaleInfo>>(`${this.resourceUrl}/${id}/locales`).pipe(
            map(response => response.data)
        );
    }

    getDatas() {
        return this.datasProduct;
    }

    setDatas(datasProduct: any) {
        this.datasProduct = datasProduct;
    }
}