import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBrandConfigurationService } from '../../../shared/interface/brand-configuration/brand-configuration-interface.service';
import { IBrandConfiguration } from '../../../shared/models/brand-configuration/brand-configuration.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class BrandConfigurationService extends GenericService<IBrandConfiguration> implements IBrandConfigurationService {
    constructor(httpClient: HttpClient) {
        super(httpClient, "brand-configuration");
    }
}