import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IBrandProductFileService } from '../../../shared/interface/brand-product-file/brand-product-file-interface.service';
import { IBrandProductFile } from '../../../shared/models/brand-product-file/brand-product-file.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class BrandProductFileService extends GenericService<IBrandProductFile> implements IBrandProductFileService {
    
  constructor(httpClient: HttpClient) {
        super(httpClient, "brand-product-file");
    }

        private filesSubject  = new Subject<void>();
        private statusSubject = new Subject<number>();

        notifyFilesChange() {
        this.filesSubject.next();
      }
    
      getFilesChangeObservable() {
        return this.filesSubject.asObservable();
      }
    
      notifyStatusChange(status: number) {
        this.statusSubject.next(status);
      }
    
      getStatusChangeObservable() {
        return this.statusSubject.asObservable();
      }

    upload(brandDomaineId: number, file: any): Observable<HttpResponse<any>> {
		const formData = new FormData();
		formData.append('file', file, file.name);
		return this.httpClient.post<any>(`${this.resourceUrl}/upload-csv?brandDomaineId=`+brandDomaineId, formData, { observe: 'response' });
	}
}