import { Provider } from '@angular/core';
import { GenericService } from './generic/generic.service';
import { AccountService } from './account/account.service';
import { AggregationScriptService } from './aggregation-script/aggregation-script.service';
import { AnalysisService } from './analysis/analysis.service';
import { AttributeValueService } from './attribute-value/attribute-value.service';
import { BrandService } from './brand/brand.service';
import { BrandAggregationService } from './brand-aggregation/brand-aggregation.service';
import { BrandConfigurationService } from './brand-configuration/brand-configuration.service';
import { BrandConnectedMediaFileService } from './brand-connected-media-file/brand-connected-media-file.service';
import { BrandDomainService } from './brand-domain/brand-domain.service';
import { BrandProductFileService } from './brand-product-file/brand-product-file.service';
import { BrandTemplateService } from './brand-template/brand-template.service';
import { ConnectedMediaService } from './connected-media/connected-media.service';
import { FlaskService } from './flask/flask.service';
import { LocaleService } from './locale/locale.service';
import { DomainUrlService } from './domain-url/domain-url.service';
import { MarketingLinkService } from './marketing-link/marketing-link.service';
import { ProductService } from './product/product.service';
import { ProductAttributeService } from './product-attribute/product-attribute.service';
import { ScanHistoryService } from './scan-history/scan-history.service';
import { TypologyService } from './typology/typology.service';
import { UserApplicationService } from './user-application/user-application.service';
import { RoleService } from './role/role.service';
import { PermissionService } from './permission/permission.service';


export const ServiceProviders: Provider = [
    { provide: 'GENERIC_SERVICE', useClass: GenericService },
    { provide: 'ACCOUNT_SERVICE', useClass: AccountService },
    { provide: 'AGGREGATION_SCRIPT_SERVICE', useClass: AggregationScriptService },
    { provide: 'ANALYSIS_SERVICE', useClass: AnalysisService},
    { provide: 'ATTRIBUTE_VALUE_SERVICE', useClass: AttributeValueService },
    { provide: 'BRAND_SERVICE', useClass: BrandService },
    { provide: 'BRAND_AGGREGATION_SERVICE', useClass: BrandAggregationService },
    { provide: 'BRAND_CONFIGURATION_SERVICE', useClass: BrandConfigurationService },
    { provide: 'BRAND_CONNECTED_MEDIA_FILE_SERVICE', useClass: BrandConnectedMediaFileService },
    { provide: 'BRAND_DOMAIN_SERVICE', useClass: BrandDomainService },
    { provide: 'BRAND_PRODUCT_FILE_SERVICE', useClass: BrandProductFileService },
    { provide: 'BRAND_TEMPLATE_SERVICE', useClass: BrandTemplateService },
    { provide: 'CONNECTED_MEDIA_SERVICE', useClass: ConnectedMediaService },
    { provide: 'DOMAIN_URL_SERVICE', useClass: DomainUrlService },
    { provide: 'FLASK_SERVICE', useClass: FlaskService },
    { provide: 'LOCALE_SERVICE', useClass: LocaleService },
    { provide: 'MARKETING_LINK', useClass: MarketingLinkService },
    { provide: 'PRODUCT_SERVICE', useClass: ProductService },
    { provide: 'PRODUCT_ATTRIBUTE_SERVICE', useClass: ProductAttributeService },
    { provide: 'SCAN_HISTORY_SERVICE', useClass: ScanHistoryService },
    { provide: 'TYPOLOGY_SERVICE', useClass: TypologyService },
    { provide: 'USER_APPLICATION_SERVICE', useClass: UserApplicationService },
    { provide: 'ROLE_SERVICE', useClass: RoleService },
    { provide: 'PERMISSION_SERVICE', useClass: PermissionService }

];