import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private translate: TranslateService,
        private loader: LoadingBarService
    ) {
        if (isPlatformBrowser(this.platformId)) {
            translate.addLangs(['en-US','fr-FR']);
        }
    }

    ngOnInit(): void {
        const storedLang = localStorage.getItem('lang');
        if (storedLang) {
            if(this.translate.getLangs().includes(storedLang)) {
                this.translate.setDefaultLang(storedLang);
            }
            else {
                this.translate.setDefaultLang("en-US");
            }
        } else {
            if(this.translate.getLangs().includes(navigator.language)) {
                this.translate.setDefaultLang(navigator.language);
            }
            else {
                this.translate.setDefaultLang("en-US");
            }
        }
    }
}
