import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { GenericService } from '../generic/generic.service';
import { IAggregationScript } from '../../../shared/models/aggregation-script/aggregation-script.model';
import { IAggregationScriptService } from '../../../shared/interface/aggregation-script/aggregation-script-interface.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class AggregationScriptService extends GenericService<IAggregationScript> implements IAggregationScriptService {
    constructor(httpClient: HttpClient) {
        super(httpClient, "aggregation-script");
    }
}