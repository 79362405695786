import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IProductRangeService } from '../../../shared/interface/product-range/product-range-interface.service';
import { IProductRange } from '../../../shared/models/product-range/product-range.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
    providedIn: 'root',
})
export class ProductRangeService extends GenericService<IProductRange> implements IProductRangeService {
    constructor(httpClient: HttpClient) {
        super(httpClient, "product-range");
    }
}
