import { Component  } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { firstValueFrom  } from "rxjs";
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientConfigurationService } from "../../../core/services/client-configuration/client-configuration.service";
import { LoginService } from "../../../core/services/login.service";
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_URL } from "../../../shared/constants/constants";
import Swal from 'sweetalert2';

@Component({
    standalone: true,
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
    imports:[CommonModule, TranslateModule,RouterModule,FormsModule,ReactiveFormsModule]
})
export class LoginComponent {
    public loading: boolean = false;
    public newUser: boolean = false;
    public show: boolean = false;
    public showAlert: boolean = false;
    public loginForm = new FormGroup({
        email: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required,Validators.email]
        }),
        password: new FormControl('', {
            nonNullable: true,
            validators: Validators.required
        })
    });  
    
    constructor(
        public  router: Router,
        private clientConfigService: ClientConfigurationService,
        private loginSvc: LoginService,
        private translate: TranslateService
    ){}

    async onSubmit(){
        this.loading = true;
        try {
            const login = this.loginForm.value["email"];
            const pwd   = this.loginForm.value["password"];
            const res   = await firstValueFrom(this.loginSvc.connect(login, pwd));
            await this.isScanGeolocViewAuthorize();
            const redirectUrl = ((res['landingUrl'] === null) || (res['landingUrl'] === "")) ? DEFAULT_URL : res['landingUrl'];
            localStorage.setItem("redirectUrl",redirectUrl);
            this.router.navigate([redirectUrl]);
        } catch (err) {
            Swal.fire({
                icon: 'error',
                title: 'Authentification',
                text: this.translate.instant('login.errorMessage'),
                width: 350
            });
        } finally {
            this.loading = false;
        }
    }

    public showPassword(): void{
        this.show = !this.show
    }

    private async isScanGeolocViewAuthorize(){
        const data = await firstValueFrom(this.clientConfigService.getList());
        localStorage.setItem('scanGeolocAuthorize', data['data']['geoloc']);
    }
}

