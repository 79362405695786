import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAttributeValueService } from '../../../shared/interface/attribute-value/attribute-value-interface.service';
import { IAttributeValue } from '../../../shared/models/attribute-value/attribute-value.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class AttributeValueService extends GenericService<IAttributeValue> implements IAttributeValueService {
    constructor(httpClient: HttpClient) {
        super(httpClient, "attribute-value");
    }
}