import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from './../../../../../core/services/nav.service';

@Component({
    selector: 'app-languages',
    templateUrl: './languages.component.html',
    styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public language: boolean = true;

    public languages: any[] = [
        {
            language: 'English',
            code: 'en-US',
            label: 'en',
            type: 'US',
            icon: 'us'
        },
        {
            language: 'Français',
            code: 'fr-FR',
            label: 'fr',
            icon: 'fr'
        }
    ];

    public selectedLanguage: any = {
        language: 'English',
        code: 'en-US',
        label: 'en',
        type: 'US',
        icon: 'us'
    }

    constructor(
        public navServices: NavService, 
        private translate: TranslateService,
    ){}
  
    ngOnInit() {
        this.getLangByCode(localStorage.getItem('lang'));
    }

    changeLanguage(lang) {
        this.translate.use(lang.code)
        this.selectedLanguage = lang;
        localStorage.setItem('lang',lang.code);
    }

    getLangByCode(code){
        switch(code){
        case 'fr-FR':
            this.selectedLanguage = {
                language: 'Français',
                code: 'fr-FR',
                label: 'fr',
                icon: 'fr'
            };
            break;
        case 'en-US': 
            this.selectedLanguage =   {
                language: 'English',
                code: 'en-US',
                label: 'en',
                type: 'US',
                icon: 'us'
            }
        default:
            break;  
        }
    }
}
