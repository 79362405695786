import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";
import { CurrentUserService } from "./current-user.services";

// Menu
export interface Menu {
    canActive?: string[];
    headTitle1?: string;
    headTitle2?: string;
    path?: string;
    title?: string;
    icon?: string;
    type?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    bookmark?: boolean;
    children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(
    private currentUserService: CurrentUserService,
    private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  isMenuItemVisible(item) {
    if (item.title == 'menu.scanHistory' && !(this.currentUserService.currentUser?.geoloc)) return false;
    if (!item.canActive) return true;
    return this.currentUserService.hasPermissions(...item.canActive);
}

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      headTitle1: "menu.title",
    },
    {
      title: "menu.productCloud",
      icon: "ecommerce",
      type: "sub",
      active: true,
      children: [
        { 
          path: "/dashboard/product-cloud/product", 
          title: "menu.listing", 
          type: "link",
          canActive: ['Product.Read']
        },
        { 
          path: "/dashboard/product-cloud/brand-product-file", 
          title: "menu.importExport", 
          type: "link",
          canActive: ['BrandProductFile.Read']
        },
        { 
          path: "/dashboard/product-cloud/brand-configuration", 
          title: "menu.brandConfiguration", 
          type: "link",
          canActive: ['BrandConfiguration.Read']
        },
        { 
          path: "/dashboard/product-cloud/brand-template", 
          title: "menu.template", 
          type: "link",
          canActive: ['BrandTemplate.Read']
        },
      ],
    },
    {
      title: "menu.connectedMedia",
      icon: "widget",
      type: "sub",
      badgeType: "light-primary",
      active: false,
      children: [
        { 
          path: "/dashboard/media-management/connected-media", 
          title: "menu.listing", 
          type: "link",
          canActive: ['ConnectedMedia.Read']
        },
        { 
          path: "/dashboard/media-management/brand-connected-media-file", 
          title: "menu.management", 
          type: "link",
          canActive: ['BrandConnectedMediaFile.Read']
        },
        { 
          path: "/dashboard/media-management/publication", 
          title: "menu.publication", 
          type: "link",
          canActive: ['ConnectedMedia.Publish']
        },
        { 
          path: "/dashboard/media-management/scan-history", 
          title: "menu.scanHistory", 
          type: "link",
          canActive: ['ScanHistory.Read']
        },
        { 
          path: "/dashboard/media-management/pairing", 
          title: "menu.pairing", 
          type: "link",
          canActive: ['ConnectedMedia.Pairing']
        },
      ],
    },
    {
      title: "Flacon",
      icon: "bonus-kit",
      type: "sub",
      active: false,
      children: [
        { 
          path: "/dashboard/pochet/flask/collection", 
          title: "menu.listing", 
          type: "link",
          canActive: ['Flask.Read']
        }
      ],
    },
    {
      title: "Analyse",
      icon: "knowledgebase",
      type: "sub",
      active: false,
      children: [
        { 
          path: "/dashboard/pochet/analysis/report", 
          title: "menu.listing", 
          type: "link",
          canActive: ['Analysis.Read']
        }
      ],
    },
    {
      title: "menu.administration",
      icon: "user",
      type: "sub",
      active: false,
      children: [
        { 
          path: "/dashboard/administration/user", 
          title: "menu.users", 
          type: "link",
          canActive: ['User.Read'],
        },
        { 
          path: "/dashboard/administration/role", 
          title: "menu.roles", 
          type: "link",
          canActive: ['Role.Read'],
        },
        { 
          path: "/dashboard/administration/auth-key", 
          title: "menu.authKeys", 
          type: "link",
          canActive: ['AuthKey.Read'],
        },
        { 
          path: "/dashboard/administration/typology", 
          title: "menu.typologies", 
          type: "link",
          canActive: ['Typology.Read'],
        },
        { 
          path: "/dashboard/administration/domain-url", 
          title: "menu.domainUrls", 
          type: "link",
          canActive: ['DomainUrl.Read'],
        },
        { 
          path: "/dashboard/administration/brand", 
          title: "menu.brands", 
          type: "link",
          canActive: ['Brand.Read'],
        }
      ],
    },
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
