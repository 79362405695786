import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      translate.addLangs(['en-US', 'fr-FR']);
    }
  }

  ngOnInit(): void {
    const storedLang = localStorage.getItem('lang');
    if (storedLang) {
      if (this.translate.getLangs().includes(storedLang)) {
        this.translate.setDefaultLang(storedLang);
      }
      else {
        this.translate.setDefaultLang("en-US");
      }
    } else {
      if (this.translate.getLangs().includes(navigator.language)) {
        this.translate.setDefaultLang(navigator.language);
      }
      else {
        this.translate.setDefaultLang("en-US");
      }
    }

    this.authService.handleRedirectObservable().subscribe();

    this.authService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$.pipe(
      filter(
        (msg: EventMessage) =>
          msg.eventType === EventType.ACCOUNT_ADDED ||
          msg.eventType === EventType.ACCOUNT_REMOVED
      )
    ).subscribe((result: EventMessage) => {
      if (this.authService.instance.getAllAccounts().length === 0) {
        window.location.pathname = '/';
      }
    });

    this.msalBroadcastService.inProgress$.pipe(
      filter(
        (status: InteractionStatus) => status === InteractionStatus.None
      ),
      takeUntil(this._destroying$)
    ).subscribe(() => {
      this.checkAndSetActiveAccount();
    });
  }

  checkAndSetActiveAccount(): void {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      activeAccount = this.authService.instance.getAllAccounts()[0];
      this.authService.instance.setActiveAccount(activeAccount);
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
