import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserService } from '../../../shared/interface/user/user-interface.service';
import { IUser } from '../../../shared/models/user/user.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class UserService extends GenericService<IUser> implements IUserService {

  constructor(httpClient: HttpClient) {
    super(httpClient, "user");
  }

  public updateAsync(entityId: string, updatedData: { associatedBrandDomainId: number | null; brandDomainIds: number[]; roleIds: number[]; }): Observable<IUser> {
    return this.httpClient.put<IUser>(`${this.resourceUrl}/${entityId}`, updatedData);
  }
}