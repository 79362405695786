import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IMarketingLink } from 'src/app/shared/models/marketing-link/marketing-link.model';
import { IBrandDomainService } from '../../../shared/interface/brand-domain/brand-domain-interface.service';
import { IBrandDomain } from '../../../shared/models/brand-domain/brand-domain.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class BrandDomainService extends GenericService<IBrandDomain> implements IBrandDomainService {
  constructor(httpClient: HttpClient) {
    super(httpClient, "brand-domain");
  }

  getMarketingLinks(): Observable<IMarketingLink[]> {
    return this.httpClient.get<IMarketingLink[]>(`${this.resourceUrl}/get-marketing-links`);
  }



  createList(entity: IBrandDomain[]): Observable<HttpResponse<IBrandDomain[]>> {
    return this.httpClient
      .post<IBrandDomain[]>(`${this.resourceUrl}/list`, entity, {
        headers: { 'Content-Type': 'application/json' },
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<IBrandDomain[]>) => {
          return response;
        })
      );
  }
}