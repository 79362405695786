import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { map } from 'rxjs';
import { CurrentUserService } from '../services/current-user.services';

export const authGuard: CanActivateFn = () => {
  const currentUserService = inject(CurrentUserService);
  if (currentUserService.currentUser) return true;
  return currentUserService.setCurrentUser().pipe(
    map(user => !!user)
  );
};
