export const environment = {
    production: false,
    apiUrl: "https://neyretportaldev-apim.azure-api.net/api",
    apiScopes: ['https://authsansara.onmicrosoft.com/scalabel-api-dev/access_as_user'],
    blobStorageUrl: "https://scalabelstorage.blob.core.windows.net/csv",
    storageUrl: "https://demo.scalabel.fr",
    gtmId: "GTM-XXXXXXXX",
    msalConfig: {
        authorityDomain: 'authsansara.b2clogin.com',
        clientId: '30cd3de2-d31f-426f-aa79-485fb3449f08',
        signIn: {
            authority: 'https://authsansara.b2clogin.com/authsansara.onmicrosoft.com/B2C_1_signin',
        }
    }
};
