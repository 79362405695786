<div [ngClass]="'ltr'">
    <div class="page-wrapper" id="canvas-bookmark" [class]="'compact-wrapper'">
        <app-header></app-header>
        <div class="page-body-wrapper">
            <div class="sidebar-wrapper" [attr.icon]="'stroke-svg'" [class.close_icon]="navServices.collapseSidebar">
                <app-sidebar></app-sidebar>
            </div>
            <div class="page-body">
                <main [@fadeInAnimation]="getRouterOutletState(o)">
                    <router-outlet #o="outlet"></router-outlet>
                </main>
            </div>
            <footer class="footer">
                <app-footer></app-footer>
            </footer>
        </div>
    </div>
</div>
<div class="bg-overlay1"></div>