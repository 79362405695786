import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBrandService } from '../../../shared/interface/brand/brand-interface.service';
import { IBrand } from '../../../shared/models/brand/brand.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class BrandService extends GenericService<IBrand> implements IBrandService {
    constructor(httpClient: HttpClient) {
        super(httpClient, "brand");
    }
}