import { Component, OnDestroy, OnInit } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { CurrentUserService } from "src/app/core/services/current-user.services";

@Component({
    selector: "app-my-account",
    templateUrl: "./my-account.component.html",
    styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit, OnDestroy {
    public login: string = "";
    public roles: string[] = [];
    public brandDomains: string[] = [];

    constructor(
        private authService: MsalService,
        private currentUserService: CurrentUserService,
    ) { }

    ngOnInit(): void {
        this.login = this.currentUserService.currentUser.displayName;
        this.roles = this.currentUserService.currentUser.roles.map(x => x.name).sort();
        this.brandDomains = this.currentUserService.currentUser.brandDomains.map(x => x.domainName).sort();
    }

    ngOnDestroy(): void {
        window.location.reload();
    }

    logout(): void {
        this.performLogout();
        this.authService.logoutRedirect();
    }

    private performLogout(): void {
        localStorage.removeItem('user');
        localStorage.removeItem('userName');
        localStorage.removeItem('role');
        localStorage.removeItem('scanGeolocAuthorize');
        localStorage.removeItem('redirectUrl');
    }
}
