import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITypologyService } from '../../../shared/interface/typology/typology-interface.service';
import { ITypology } from '../../../shared/models/typology/typology.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class TypologyService extends GenericService<ITypology> implements ITypologyService {
  
  constructor(httpClient: HttpClient) {
    super(httpClient, "typology");
  }
}