import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { authGuard } from '../../core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'dashboard',
    loadComponent: () => import('./../../shared/components/layout/content/content.component').then(c => c.ContentComponent),
    canActivate: [MsalGuard, authGuard],
    children: [
      { path: 'administration', loadChildren: () => import('./administration/administration.routes').then(r => r.routes) },
      { path: 'media-management', loadChildren: () => import('./media-management/media-management.routes').then(r => r.routes) },
      { path: 'metrics', loadChildren: () => import('./metrics/metrics.routes').then(r => r.routes) },
      { path: 'product-cloud', loadChildren: () => import('./product-cloud/product-cloud.routes').then(r => r.routes) },
      { path: 'pochet', loadChildren: () => import('./../pochet/pochet.routes').then(r => r.routes) }
    ]
  },
  {
    path: 'login-redirect',
    canActivate: [MsalGuard, authGuard],
    loadComponent: () => import('./login-redirect/login-redirect.component').then(c => c.LoginRedirectComponent) 
  },
  {
    path: 'new-user',
    canActivate: [MsalGuard, authGuard],
    loadComponent: () => import('./new-user/new-user.component').then(c => c.NewUserComponent)
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class DashboardRoutingModule { }