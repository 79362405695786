import { Provider } from '@angular/core';
import { AggregationScriptService } from './aggregation-script/aggregation-script.service';
import { AnalysisService } from './analysis/analysis.service';
import { AttributeValueService } from './attribute-value/attribute-value.service';
import { AuthKeyService } from './auth-key/auth-key.service';
import { BrandAggregationService } from './brand-aggregation/brand-aggregation.service';
import { BrandConfigurationService } from './brand-configuration/brand-configuration.service';
import { BrandConnectedMediaFileService } from './brand-connected-media-file/brand-connected-media-file.service';
import { BrandDomainService } from './brand-domain/brand-domain.service';
import { BrandProductFileService } from './brand-product-file/brand-product-file.service';
import { BrandTemplateService } from './brand-template/brand-template.service';
import { BrandService } from './brand/brand.service';
import { CategoryService } from './category/category.service';
import { ConnectedMediaService } from './connected-media/connected-media.service';
import { ConnectedMediaTypeService } from './connected-media-type/connected-media-type.service';
import { DomainUrlService } from './domain-url/domain-url.service';
import { FlaskService } from './flask/flask.service';
import { GenericService } from './generic/generic.service';
import { LocaleService } from './locale/locale.service';
import { MarketingLinkService } from './marketing-link/marketing-link.service';
import { ProductAttributeService } from './product-attribute/product-attribute.service';
import { ProductRangeService } from './product-range/product-range.service';
import { ProductService } from './product/product.service';
import { RoleService } from './role/role.service';
import { ScanHistoryService } from './scan-history/scan-history.service';
import { TypologyService } from './typology/typology.service';
import { UserService } from './user/user.service';

export const ServiceProviders: Provider = [
  { provide: 'GENERIC_SERVICE', useClass: GenericService },
  { provide: 'AGGREGATION_SCRIPT_SERVICE', useClass: AggregationScriptService },
  { provide: 'ANALYSIS_SERVICE', useClass: AnalysisService },
  { provide: 'ATTRIBUTE_VALUE_SERVICE', useClass: AttributeValueService },
  { provide: 'AUTH_KEY_SERVICE', useClass: AuthKeyService },
  { provide: 'BRAND_SERVICE', useClass: BrandService },
  { provide: 'BRAND_AGGREGATION_SERVICE', useClass: BrandAggregationService },
  { provide: 'BRAND_CONFIGURATION_SERVICE', useClass: BrandConfigurationService },
  { provide: 'BRAND_CONNECTED_MEDIA_FILE_SERVICE', useClass: BrandConnectedMediaFileService },
  { provide: 'BRAND_DOMAIN_SERVICE', useClass: BrandDomainService },
  { provide: 'BRAND_PRODUCT_FILE_SERVICE', useClass: BrandProductFileService },
  { provide: 'BRAND_TEMPLATE_SERVICE', useClass: BrandTemplateService },
  { provide: 'CONNECTED_MEDIA_SERVICE', useClass: ConnectedMediaService },
  { provide: 'DOMAIN_URL_SERVICE', useClass: DomainUrlService },
  { provide: 'FLASK_SERVICE', useClass: FlaskService },
  { provide: 'LOCALE_SERVICE', useClass: LocaleService },
  { provide: 'MARKETING_LINK', useClass: MarketingLinkService },
  { provide: 'PRODUCT_SERVICE', useClass: ProductService },
  { provide: 'PRODUCT_ATTRIBUTE_SERVICE', useClass: ProductAttributeService },
  { provide: 'ROLE_SERVICE', useClass: RoleService },
  { provide: 'SCAN_HISTORY_SERVICE', useClass: ScanHistoryService },
  { provide: 'TYPOLOGY_SERVICE', useClass: TypologyService },
  { provide: 'USER_SERVICE', useClass: UserService },
  { provide: 'GENERIC_SERVICE', useClass: GenericService },
  { provide: 'AGGREGATION_SCRIPT_SERVICE', useClass: AggregationScriptService },
  { provide: 'ANALYSIS_SERVICE', useClass: AnalysisService },
  { provide: 'ATTRIBUTE_VALUE_SERVICE', useClass: AttributeValueService },
  { provide: 'BRAND_SERVICE', useClass: BrandService },
  { provide: 'BRAND_AGGREGATION_SERVICE', useClass: BrandAggregationService },
  { provide: 'BRAND_CONFIGURATION_SERVICE', useClass: BrandConfigurationService },
  { provide: 'BRAND_CONNECTED_MEDIA_FILE_SERVICE', useClass: BrandConnectedMediaFileService },
  { provide: 'BRAND_DOMAIN_SERVICE', useClass: BrandDomainService },
  { provide: 'BRAND_PRODUCT_FILE_SERVICE', useClass: BrandProductFileService },
  { provide: 'BRAND_TEMPLATE_SERVICE', useClass: BrandTemplateService },
  { provide: 'CATEGORY_SERVICE', useClass: CategoryService },
  { provide: 'CONNECTED_MEDIA_SERVICE', useClass: ConnectedMediaService },
  { provide: 'CONNECTED_MEDIA_TYPE_SERVICE', useClass: ConnectedMediaTypeService},
  { provide: 'DOMAIN_URL_SERVICE', useClass: DomainUrlService },
  { provide: 'FLASK_SERVICE', useClass: FlaskService },
  { provide: 'LOCALE_SERVICE', useClass: LocaleService },
  { provide: 'MARKETING_LINK', useClass: MarketingLinkService },
  { provide: 'PRODUCT_SERVICE', useClass: ProductService },
  { provide: 'PRODUCT_ATTRIBUTE_SERVICE', useClass: ProductAttributeService },
  { provide: 'PRODUCT_RANGE_SERVICE', useClass: ProductRangeService },
  { provide: 'SCAN_HISTORY_SERVICE', useClass: ScanHistoryService },
  { provide: 'TYPOLOGY_SERVICE', useClass: TypologyService },
  { provide: 'ROLE_SERVICE', useClass: RoleService },
];