import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMarketingLinkService } from '../../../shared/interface/marketing-link/marketing-link-interface.service';
import { IMarketingLink } from '../../../shared/models/marketing-link/marketing-link.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class MarketingLinkService extends GenericService<IMarketingLink> implements IMarketingLinkService {
  
  constructor(httpClient: HttpClient) {
    super(httpClient, "marketing-link");
  }
}