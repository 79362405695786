import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILocaleService } from '../../../shared/interface/locale/locale-interface.service';
import { ILocale } from '../../../shared/models/locale/locale.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class LocaleService extends GenericService<ILocale> implements ILocaleService {

  constructor(httpClient: HttpClient) {
    super(httpClient, "locale");
  }
}