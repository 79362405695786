import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from '../../../configs/application-config.service';
import { IAccountService } from '../../../shared/interface/account/account-interface.service';
import { IPasswordConfirmation } from '../../../shared/models/account/password-confirmation.model';
import { IRole } from '../../../shared/models/application-user/role.model';
import { IUser } from '../../../shared/models/application-user/user.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
    providedIn: 'root',
})
export class AccountService extends GenericService<IUser> implements IAccountService {
    private currentLocaleCode: string | undefined;
    private applicationAvailableLocales: { code: string, label: string, shortLabel: string, icon: string }[] = [
        { code: "en-US", label: "English (US)", shortLabel: "EN", icon: "us" },
        { code: "fr-FR", label: "Français (FR)", shortLabel: "FR", icon: "fr" }
    ];
    private applicationAvailableLocaleCodes: string[] = this.applicationAvailableLocales.map(x => x.code);

    constructor(
        httpClient: HttpClient,
        applicationConfigService: ApplicationConfigService
    ) {
        super(httpClient, applicationConfigService, "account");
    }

    createWithRole(user: IUser): Observable<HttpResponse<IUser>> {
        const headers = { 'Content-Type': 'application/json' };
        return this.httpClient
            .post<IUser>(`${this.resourceUrl}/register-with-role `, user, {
                headers: headers,
                observe: 'response',
            })
            .pipe(
                map((response: HttpResponse<IUser>) => {
                    return response;
                })
            );
    }

    getAllUsers(): Observable<any> {
        return this.httpClient.get<any>(`${this.resourceUrl}/get-all-users`);
    }

    getRoles(): Observable<IRole[]> {
        return this.httpClient.get<any>(`${this.resourceUrl}/get-all-roles`);
    }

    getUsers(): Observable<IUser[]> {
        return this.httpClient.get<IUser[]>(`${this.resourceUrl}/get-users`);
    }

    resetPasswordRequest(mail: string) {
        return this.httpClient.post<any>(`${this.resourceUrl}/reset-password-request/${mail}/${localStorage.getItem('lang')}`, null, { observe: 'response' });
    }

    resetPasswordValidation(cryptedToken: string) {
        let filterParams: HttpParams = new HttpParams();
        filterParams = filterParams.set("cryptedToken", cryptedToken);
        return this.httpClient.get<any>(`${this.resourceUrl}/reset-password-validation`, {
            params: filterParams,
        });
    }

    resetPasswordDone(cryptedToken: string) {
        let filterParams: HttpParams = new HttpParams();
        filterParams = filterParams.set("cryptedToken", cryptedToken);
        return this.httpClient.get<any>(`${this.resourceUrl}/reset-password-done`, {
            params: filterParams,
        });
    }

    resetPasswordConfirmation(confirmation: IPasswordConfirmation) {
        const headers = { 'Content-Type': 'application/json' };
        this.setLocaleFromStorage(this.applicationAvailableLocaleCodes);
        if (!this.currentLocaleCode) this.setLocaleFromNavigator(this.applicationAvailableLocaleCodes);
        if (!this.currentLocaleCode) this.applicationAvailableLocaleCodes[0] ?? "fr-FR";
        confirmation.locale = this.currentLocaleCode;
        return this.httpClient
            .put<IPasswordConfirmation>(`${this.resourceUrl}/reset-password-confirmation`, confirmation, {
                headers: headers,
                observe: 'response',
            })
            .pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                })
            );
    }

    private setLocaleFromStorage(availableLocaleCodes: string[]): void {
        const storageLocale = localStorage.getItem('lang');
        if (storageLocale) {
            if (availableLocaleCodes.includes(storageLocale)) this.currentLocaleCode = storageLocale;
            else this.currentLocaleCode = availableLocaleCodes.find(x => x.startsWith(storageLocale));
        }
    }

    private setLocaleFromNavigator(availableLocaleCodes: string[]): void {
        const navigatorLocale = navigator.language;
        if (navigatorLocale) {
            if (availableLocaleCodes.includes(navigatorLocale)) this.currentLocaleCode = navigatorLocale;
            else this.currentLocaleCode = availableLocaleCodes.find(x => x.startsWith(navigatorLocale));
        }
    }
}