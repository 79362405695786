import { CommonModule, DecimalPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SwiperModule } from "swiper/angular";
import { LayoutService } from "../core/services/layout.service";
import { NavService } from "../core/services/nav.service";
import { CustomizerComponent } from "./components/customizer/customizer.component";
import { ErrorFieldComponent } from './components/error-field/error-field.component';
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { FormComponent } from './components/form/form.component';
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { PagingComponent } from "./components/paging/paging.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
import { AdjustImageHeightDirective } from './directives/adjust-image-height.directive';
import { CheckKpiTextDirective } from './directives/check-kpi-text.directive';
import { DisableKeyPressDirective } from "./directives/disable-key-press.directive";
import { EqualHeightDirective } from './directives/equal-height.directive';
import { NgbdSortableHeader } from "./directives/NgbdSortableHeader";
import { OnlyAlphabetsDirective } from "./directives/only-alphabets.directive";
import { OnlyNumbersDirective } from "./directives/only-numbers.directive";
import { RoleDirective } from './directives/role.directive';
import { ShowOptionsDirective } from "./directives/show-options.directive";
import { ThousandSeparatorPipe } from './pipes/thousand-separator.pipe';
import { ValidationPipe } from './pipes/validation.pipe';

@NgModule({
    declarations: [
        PagingComponent,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        ContentComponent,
        CustomizerComponent,
        FeatherIconsComponent,
        FullComponent,
        ShowOptionsDirective,
        DisableKeyPressDirective,
        OnlyAlphabetsDirective,
        OnlyNumbersDirective,
        TapToTopComponent,
        LanguagesComponent,
        MyAccountComponent,
        NgbdSortableHeader,
        SvgIconComponent,
        FormComponent,
        RoleDirective,
        EqualHeightDirective,
        AdjustImageHeightDirective,
        ErrorFieldComponent,
        ValidationPipe,
        ThousandSeparatorPipe,
        CheckKpiTextDirective
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        TranslateModule.forChild(),
        CarouselModule,
        SwiperModule
    ],
    providers: [
        NavService,
        LayoutService,
        DecimalPipe
    ],
    exports: [
        NgbModule,
        LanguagesComponent,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        FeatherIconsComponent,
        PagingComponent,
        TapToTopComponent,
        ErrorFieldComponent,
        DisableKeyPressDirective,
        OnlyAlphabetsDirective,
        EqualHeightDirective,
        OnlyNumbersDirective,
        AdjustImageHeightDirective,
        NgbdSortableHeader,
        SvgIconComponent,
        ValidationPipe,
        ThousandSeparatorPipe,
        CheckKpiTextDirective
    ],
})
export class SharedModule { }
