<div class="media profile-media">
    <div class="media-body">
        <span>{{ login }}</span>
        <p class="mb-0 font-roboto">
            <span>
                {{ roles.length }} {{ 'common.roles' | translate }}
            </span>
            <i class="middle fa fa-angle-down"></i>
        </p>
    </div>
</div>
<ul class="profile-dropdown onhover-show-div">
    <li (click)="logout()" class="logout">
        <app-feather-icons [icon]="'log-out'"></app-feather-icons>
        <span>{{ 'account.logout' | translate }}</span>
    </li>
    <li>
        <span class="small-item-list-title">{{ 'role.list' | translate }}</span>
        <div class="small-item-list">
            <span *ngIf="roles.length == 0" class="small-item">{{ 'role.none' | translate }}</span>
            <span *ngFor="let role of roles" class="small-item">{{ role }}</span>
        </div>
    </li>
    <li>
        <span class="small-item-list-title">{{ 'brandDomain.list' | translate }}</span>
        <div class="small-item-list">
            <span *ngIf="brandDomains.length == 0" class="small-item">{{ 'brandDomain.none' | translate }}</span>
            <span *ngFor="let brandDomain of brandDomains" class="small-item">{{ brandDomain }}</span>
        </div>
    </li>
</ul>