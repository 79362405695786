import {
    HttpClient,
    HttpErrorResponse,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../configs/environments/environment';
import { IGenericService } from '../../../shared/interface/generic/generic-interface.service';
import { IPagingFilter } from '../../../shared/models/helper/paging-filter.model';
import { IResponse } from '../../../shared/models/response/response.model';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
    providedIn: 'root',
})
export class GenericService<T> implements IGenericService<T> {
    public resourceUrl: string;
    constructor(
        public httpClient: HttpClient,
        public resource: string,
    ) {
        this.resourceUrl = `${environment.apiUrl}/${resource}`;
    }

    create(entity: T): Observable<HttpResponse<T>> {
        const headers = { 'Content-Type': 'application/json' };
        return this.httpClient
            .post<T>(this.resourceUrl, entity, {
                headers: headers,
                observe: 'response',
            })
            .pipe(
                map((response: HttpResponse<T>) => {
                    return response;
                }),
            );
    }

    update(entity: T, entityId: any): Observable<HttpResponse<T>> {
        const headers = { 'Content-Type': 'application/json' };
        return this.httpClient
            .put<T>(`${this.resourceUrl}/${entityId}`, entity, {
                headers: headers,
                observe: 'response',
            })
            .pipe(
                map((response: HttpResponse<T>) => {
                    return response;
                }),
            );
    }

    delete(entityId: number): Observable<HttpResponse<void>> {
        return this.httpClient.delete<void>(`${this.resourceUrl}/${entityId}`, {
            observe: 'response',
        });
    }

    getDetail(entityId: number) {
        return this.httpClient.get<T>(`${this.resourceUrl}/${entityId}`);
    }

    getDetailByLocale(entityId: number, i18n: string) {
        return this.httpClient.get<T>(
            `${this.resourceUrl}/${entityId}/${i18n}`,
        );
    }

    getDetailStr(entityId: string): Observable<T> {
        return this.httpClient.get<T>(`${this.resourceUrl}/${entityId}`);
    }

    getList(): Observable<T[]> {
        return this.httpClient.get<T[]>(`${this.resourceUrl}`);
    }

    getByDomain(domainId?: number): Observable<T[]> {
        let params = new HttpParams();
        if (domainId) {
            params = params.append('brandDomainId', domainId.toString());
        }
        return this.httpClient.get<T[]>(this.resourceUrl, { params });
    }

    getByBrandDomains(brandDomainIds: number[]): Observable<T[]> {
        let params = new HttpParams();
        if (brandDomainIds?.length > 0) {
            brandDomainIds
                .filter(
                    (brandDomainId) =>
                        brandDomainId !== null && brandDomainId !== undefined,
                )
                .forEach((brandDomainId) => {
                    params = params.append(
                        'brandDomainIds',
                        brandDomainId.toString(),
                    );
                });
        }
        return this.httpClient.get<T[]>(this.resourceUrl, { params });
    }

    getPaging(filters: IPagingFilter[]): Observable<T[]> {
        let filterParams: HttpParams = new HttpParams();
        filters.forEach((element) => {
            filterParams = filterParams.set(element.field, element.value);
        });
        return this.httpClient.get<T[]>(`${this.resourceUrl}/get-paging`, {
            params: filterParams,
        });
    }

    getEntities(filters: IPagingFilter[]): Observable<T[]> {
        let filterParams: HttpParams = new HttpParams();
        filters.forEach((element) => {
            filterParams = filterParams.set(element.field, element.value);
        });
        return this.httpClient.get<T[]>(`${this.resourceUrl}`, {
            params: filterParams,
        });
    }

    getEntityList(): Observable<HttpResponse<IResponse<T>>> {
        return this.httpClient
            .get<
                IResponse<T>
            >(`${this.resourceUrl}/list`, { observe: 'response' })
            .pipe(
                map((response: HttpResponse<IResponse<T>>) => response),
                catchError(this.handleApiError),
            );
    }

    getPagingWithBrandDomainIds(
        filters: IPagingFilter[],
        ids: number[],
    ): Observable<T[]> {
        let filterParams: HttpParams = new HttpParams();
        filters.forEach((element) => {
            filterParams = filterParams.set(element.field, element.value!);
        });
        let url = `${this.resourceUrl}?`;
        for (const id of ids) {
            url += `brandDomainIds=${id}&`;
        }
        return this.httpClient.get<T[]>(url, {
            params: filterParams,
        });
    }

    public getPagingWithIdsAndCustomParam(
        filters: IPagingFilter[],
        brandDomainIds: number[],
        customParamValues: number[],
        customParamName: string,
    ): Observable<T[]> {
        let params = new HttpParams();
        filters.forEach((filter) => {
            params = params.append(filter.field, filter.value!);
        });
        const specificParams: { [key: string]: number[] } = {};
        this.addParamIfNotEmpty(
            specificParams,
            brandDomainIds,
            'brandDomainIds',
        );
        this.addParamIfNotEmpty(
            specificParams,
            customParamValues,
            customParamName,
        );
        params = params.appendAll(specificParams);
        return this.httpClient.get<T[]>(this.resourceUrl, { params });
    }

    public createEntity(entity: T): Observable<HttpResponse<IResponse<T>>> {
        return this.httpClient
            .post<
                IResponse<T>
            >(this.resourceUrl, entity, { observe: 'response' })
            .pipe(
                map((response: HttpResponse<IResponse<T>>) => response),
                catchError(this.handleApiError),
            );
    }

    public deleteEntity(
        entityId: number,
    ): Observable<HttpResponse<IResponse<T>>> {
        return this.httpClient
            .delete<
                IResponse<T>
            >(`${this.resourceUrl}/${entityId}`, { observe: 'response' })
            .pipe(
                map((response: HttpResponse<IResponse<T>>) => response),
                catchError(this.handleApiError),
            );
    }

    public updateEntity(
        entity: T,
        entityId: number,
    ): Observable<HttpResponse<IResponse<T>>> {
        return this.httpClient
            .put<
                IResponse<T>
            >(`${this.resourceUrl}/${entityId}`, entity, { observe: 'response' })
            .pipe(
                map((response: HttpResponse<IResponse<T>>) => response),
                catchError(this.handleApiError),
            );
    }

    private addParamIfNotEmpty(
        params: { [key: string]: number[] },
        values: number[],
        paramName: string,
    ): void {
        if (values.length > 0) {
            params[paramName] = values;
        }
    }

    private handleApiError(error: HttpErrorResponse) {
        return throwError(() => error);
    }
}
