import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable, tap } from 'rxjs';
import { environment } from '../../configs/environments/environment';
import { IBrandDomain } from 'src/app/shared/models/brand-domain/brand-domain.model';
import { IRole } from 'src/app/shared/models/role/role.model';

export type CurrentUser = {
  id: string;
  displayName: string;
  email: string;
  geoloc: boolean;
  permissions: string[];
  brandDomainIds: number[];
  brandDomains: IBrandDomain[];
  roles: IRole[];
  associatedBrandDomain: IBrandDomain | null;
  landingUrl: string | null;
};

@Injectable({
  providedIn: "root",
})
export class CurrentUserService {

  private readonly _apiEndpoint = `${environment.apiUrl}/account`;
  private _currentUser: CurrentUser | undefined;

  public get currentUser(): CurrentUser | undefined {
    return this._currentUser;
  }

  constructor(private http: HttpClient) { }

  public setCurrentUser(): Observable<CurrentUser> {
    return this.http.get<CurrentUser>(`${this._apiEndpoint}/me`).pipe(
      tap(user => this._currentUser = user)
    );
  }

  public hasPermissions(...permissions: string[]): boolean {
    if (this._currentUser === undefined) return false;
    let authorized = true;
    permissions.forEach(permission => authorized &&= this._currentUser!.permissions.includes(permission));
    return authorized;
  }
}
