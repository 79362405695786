import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IConnectedMediaTypeService } from '../../../shared/interface/connected-media-type/connected-media-type-interface.service';
import { IConnectedMediaType } from '../../../shared/models/connected-media-type/connected-media-type.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
    providedIn: 'root',
})
export class ConnectedMediaTypeService extends GenericService<IConnectedMediaType> implements IConnectedMediaTypeService {
    constructor(httpClient: HttpClient) {
        super(httpClient, "connected-media-type");
    }
}
