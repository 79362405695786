import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, filter, Observable, of, tap, throwError } from "rxjs";

@Injectable()
export class LoginInterceptor implements HttpInterceptor{

    constructor(private router: Router){}
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        req = req.clone({
            setHeaders : {
                Authorization: `Bearer ${token}` 
            }
        })
        return next.handle(req).pipe(
            catchError((error) =>  {
                if (error instanceof HttpErrorResponse){
                    let tokenExpiration: any = new Date(localStorage.getItem("tokenExpiration"));
                    if( tokenExpiration < new Date()) {
                        this.router.navigate(['/login']);
                    } else if (error.status === 401 || error.status === 403){
                        this.router.navigate(['/'+error.status]);
                    }                    
                }
                return throwError(() => error);
            })
        );
    }
}
