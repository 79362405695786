import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAuthKeyService } from '../../../shared/interface/auth-key/auth-key.interface';
import { IAuthKey } from '../../../shared/models/auth-key/auth-key.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class AuthKeyService extends GenericService<IAuthKey> implements IAuthKeyService {

  constructor(httpClient: HttpClient) {
    super(httpClient, "auth-key");
  }

  public createAsync(data: { name: string, brandDomainIds: number[]; roleIds: number[]; expirationDate: Date; }): Observable<{ id: string, accessKey: string }> {
    return this.httpClient.post<{ id: string, accessKey: string }>(`${this.resourceUrl}`, data);
  }

  public revokeAsync(entityId: string): Observable<IAuthKey> {
    return this.httpClient.post<IAuthKey>(`${this.resourceUrl}/${entityId}/revoke`, {});
  }
}