import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error401Component } from './error/error401/error401.component';
import { Error403Component } from './error/error403/error403.component';
import { Error404Component } from './error/error404/error404.component';

const routes: Routes = [
	{
		path: '401',
		component: Error401Component
	},
	{
		path: '403',
		component: Error403Component
	},
	{
		path: '404',
		component: Error404Component
	},
	{
		path: ':pc/:digitalId',
		loadComponent: () => import('./product-page/product-page.component').then(c => c.PageComponent)
	},
	{
		path: '',
		redirectTo: 'login-redirect'
	},
	{
		path: '**',
		redirectTo: '404'
	}
];

@NgModule({
	imports: [
		RouterModule.forChild(routes)
	],
	exports: [
		RouterModule
	]
})
export class PublicRoutingModule { }