import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
    canActive?: string[];
    headTitle1?: string;
    headTitle2?: string;
    path?: string;
    title?: string;
    icon?: string;
    type?: string;
    badgeType?: string;
    badgeValue?: string;
    active?: boolean;
    bookmark?: boolean;
    children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  checkPermission(permission: string) {
    let hasPermission = false;
    if(localStorage.getItem("role")?.includes("SUPERADMIN")){
      hasPermission = true;
      return hasPermission;
    }
    if (localStorage.getItem("permissions")) {
      const perms = localStorage.getItem("permissions").split(',');
      if (perms.includes(permission.toString())) {
        hasPermission = true;
      }
  }
    return hasPermission;
  }

  isMenuItemVisible(item) {
    if(item.title == 'menu.scanHistory' && !localStorage.getItem("scanGeolocAuthorize")){
      return false;
    }
    if (!item.canActive) {
        return true;
    }
    return this.checkPermission(item.canActive)
}

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      headTitle1: "menu.title",
    },
    {
      title: "menu.productCloud",
      icon: "ecommerce",
      type: "sub",
      active: true,
      canActive: ['Menu.ProductCloud'],
      children: [
        { 
          path: "/dashboard/product-cloud/product", 
          title: "menu.listing", 
          type: "link",
          canActive: ['SubMenu.Product']
        },
        { 
          path: "/dashboard/product-cloud/brand-product-file", 
          title: "menu.importExport", 
          type: "link",
          canActive: ['SubMenu.BrandProductFile']
        },
        { 
          path: "/dashboard/product-cloud/brand-configuration", 
          title: "menu.brandConfiguration", 
          type: "link",
          canActive: ['SubMenu.BrandConfiguration']
        },
        /**{ 
          path: "/dashboard/product-cloud/brand-aggregation", 
          title: "menu.aggregation", 
          type: "link",
          canActive: ['SubMenu.BrandAggregation']
        },*/
        { 
          path: "/dashboard/product-cloud/brand-template", 
          title: "menu.template", 
          type: "link",
          canActive: ['SubMenu.Template']
        },
      ],
    },
    {
      title: "menu.connectedMedia",
      icon: "widget",
      type: "sub",
      badgeType: "light-primary",
      active: false,
      canActive: ['Menu.ConnectedMedia'],
      children: [
        { 
          path: "/dashboard/media-management/connected-media", 
          title: "menu.listing", 
          type: "link",
          canActive: ['SubMenu.ConnectedMediaListing']
        },
        { 
          path: "/dashboard/media-management/brand-connected-media-file", 
          title: "menu.importExport", 
          type: "link",
          canActive: ['SubMenu.ImportExport']
        },
        { 
          path: "/dashboard/media-management/publication", 
          title: "menu.publication", 
          type: "link",
          canActive: ['SubMenu.Publish']
        },
        { 
          path: "/dashboard/media-management/scan-history", 
          title: "menu.scanHistory", 
          type: "link",
          canActive: ['SubMenu.ScanHistory']
        },
        { 
          path: "/dashboard/media-management/pairing", 
          title: "menu.pairing", 
          type: "link",
          //canActive: ['SubMenu.pairing']
        },
      ],
    },
    {
      title: "Flacon",
      icon: "bonus-kit",
      type: "sub",
      active: false,
      canActive: ['Menu.Flask'],
      children: [
        { 
          path: "/dashboard/pochet/flask/collection", 
          title: "menu.listing", 
          type: "link",
          canActive: ['SubMenu.FlaskListing']
        }
      ],
    },
    {
      title: "Analyse",
      icon: "knowledgebase",
      type: "sub",
      active: false,
      canActive: ['Menu.Analyse'],
      children: [
        { 
          path: "/dashboard/pochet/analysis/report", 
          title: "menu.listing", 
          type: "link",
          canActive: ['SubMenu.Analyse']
        }
      ],
    },
    {
      title: "menu.administration",
      icon: "user",
      type: "sub",
      active: false,
      canActive: ['Menu.Administrator'],
      children: [
        { 
          path: "/dashboard/administration/user", 
          title: "menu.users", 
          type: "link",
          canActive: ['SubMenu.User'],
        },
        { 
          path: "/dashboard/administration/role", 
          title: "menu.roles", 
          type: "link",
          canActive: ['SubMenu.Role'],
        },
        { 
          path: "/dashboard/administration/typology", 
          title: "menu.typologies", 
          type: "link",
          canActive: ['SubMenu.Typologie'],
        },
        { 
          path: "/dashboard/administration/domain-url", 
          title: "menu.domainUrls", 
          type: "link",
          canActive: ['SubMenu.DomainUrls'],
        },
        { 
          path: "/dashboard/administration/brand", 
          title: "menu.brands", 
          type: "link",
          canActive: ['SubMenu.Brand'],
        }
      ],
    },
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
