import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IRoleService } from '../../../shared/interface/role/role-interface.service';
import { IRole } from '../../../shared/models/role/role.model';
import { GenericService } from '../generic/generic.service';
import { Observable } from 'rxjs';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class RoleService extends GenericService<IRole> implements IRoleService {

  constructor(httpClient: HttpClient) {
    super(httpClient, "role");
  }

  public createAsync(data: { name: string, brandDomainIds: number[]; permissions: string[]; }): Observable<IRole> {
    return this.httpClient.post<IRole>(`${this.resourceUrl}`, data);
  }

  public updateAsync(entityId: number, data: { name: string, brandDomainIds: number[]; permissions: string[]; }): Observable<IRole> {
    return this.httpClient.put<IRole>(`${this.resourceUrl}/${entityId}`, data);
  }
}