import { Component, Inject } from '@angular/core';
import { CurrentUserService } from '../../../core/services/current-user.services';
import { IGenericService } from '../../../shared/interface/generic/generic-interface.service';
import { IBrandDomain } from '../../models/brand-domain/brand-domain.model';
import { ICategory } from '../../models/category/category.model';
import { IPagingFilter } from '../../models/helper/paging-filter.model';

@Component({
    selector: 'app-paging',
    templateUrl: './paging.component.html',
    styleUrls: ['./paging.component.scss']
})
export class PagingComponent<T> {
    public brandDomains: IBrandDomain[] = [];
    public currentPage: number = 1;
    public entityList: T[] = [];
    public genericFilters: IPagingFilter[] = [];
    public isAlreadyFiltered: boolean = false;
    public isFiltered: boolean = false;
    public limiteInf: number = 1;
    public limiteSup: number = 10;
    public loading: boolean = false;
    public nbEntityFiltered: number;
    public nbPages: number = 0;
    public nbTotalEntities: number;
    public numbersArray: number[];
    public pageSize: number = 10;
    public selectedBrandDomains: IBrandDomain[] = [];
    public selectedBrandDomainIds: number[] = [];

    public catDomFilters: { domains: number[], categories: ICategory[] } = {
        domains: [],
        categories: []
    };

    public loader: boolean = false;

    constructor(
        @Inject('GENERIC_SERVICE') protected genericService: IGenericService<T>,
        protected currentUserService: CurrentUserService,
    ) {
        if (currentUserService != null) {
            this.getBrandDomainList();
        }
    }

    public brandDomainsChange(): void {
        this.selectedBrandDomainIds = this.selectedBrandDomains.map(({ id }) => id!);
        this.getPagingWithBrandDomainIds()
    }

    public getBrandDomainList(): void {
        this.brandDomains = this.currentUserService.currentUser!.brandDomains;
    }

    protected buildNumbersArray(nbPages: number): number[] {
        let result: number[] = [];
        for (let i = Math.max(this.currentPage - 2, 1); i <= Math.min(this.currentPage + 2, nbPages); i++) {
            result.push(i);
        }
        return result;
    }

    protected getListing(): void {
        const filtersWithPaging = this.generateFiltersWithPaging();
        this.genericService.getEntities(filtersWithPaging).subscribe((res) => {
            this.buildListingAndPaging(res['data']);
        });
    }

    protected getPaging(): void {
        const filtersWithPaging = this.generateFiltersWithPaging();
        this.genericService.getPaging(filtersWithPaging).subscribe((res) => {
            this.buildListingAndPaging(res);
        });
    }

    protected getEntities(): void {
        const filtersWithPaging = this.generateFiltersWithPaging();
        this.genericService.getEntities(filtersWithPaging).subscribe((res) => {
            this.buildRestfulListingAndPaging(res);
        });
    }

    protected getPagingWithBrandDomainIds(): void {
        const filtersWithPaging = this.generateFiltersWithPaging();
        this.genericService.getPagingWithBrandDomainIds(filtersWithPaging, this.selectedBrandDomainIds).subscribe((res) => {
            this.buildListingAndPaging(res);
        });
    }

    protected loadPagedEntities(catDomFilters: any, entityIds: string): void {
        this.loader = true;
        const filtersWithPaging = this.generateFiltersWithPaging();
        this.genericService.getPagingWithIdsAndCustomParam(filtersWithPaging, catDomFilters.domains, catDomFilters.categories, entityIds).subscribe({
            next: (res) => {
                this.loader = false;
                this.buildListingAndPaging(res);
            },
            error: (error) => {
                this.loader = false;
            }
        });
    }

    protected mergeFilter(filter: IPagingFilter): void {
        const filterIndex = this.genericFilters.findIndex(x => x.field === filter.field);
        const filterValue = (filter.value ?? "").trim();

        if (filterValue === "") {
            if (filterIndex > -1) this.genericFilters.splice(filterIndex, 1);
        } else {
            if (filterIndex > -1) this.genericFilters[filterIndex].value = filterValue;
            else this.genericFilters.push(filter);
        }

        this.currentPage = 1;
    }

    protected onPageChange(newPage: number): void {
        this.currentPage = newPage;
        this.limiteInf = (newPage - 1) * this.pageSize + 1;
        this.limiteSup = this.limiteInf + (this.currentPage < this.nbPages ? + this.pageSize : this.nbTotalEntities % this.pageSize) - 1;
        this.numbersArray = this.buildNumbersArray(this.nbPages);
    }

    protected onSizeChange(pageSize: number): void {
        this.pageSize = pageSize;
        this.limiteInf = 1;
        this.limiteSup = this.limiteInf + this.pageSize;
        this.currentPage = 1;
    }

    protected buildListingAndPaging(result: any): void {
        this.entityList = [...result['pagingList']];
        this.nbTotalEntities = result['entityCount'];
        const nbPageTmp = this.nbTotalEntities / this.pageSize;
        this.nbPages = Number.isInteger(nbPageTmp) ? nbPageTmp : Math.floor(nbPageTmp) + 1;
        this.numbersArray = this.buildNumbersArray(this.nbPages);
        this.limiteSup = Math.min(this.nbTotalEntities, this.limiteInf + this.pageSize - 1);
    }

    protected buildRestfulListingAndPaging(result: any): void {
        this.entityList = [...result['data']['pagingList']];
        this.nbTotalEntities = result['data']['entityCount'];
        const nbPageTmp = this.nbTotalEntities / this.pageSize;
        this.nbPages = Number.isInteger(nbPageTmp) ? nbPageTmp : Math.floor(nbPageTmp) + 1;
        this.numbersArray = this.buildNumbersArray(this.nbPages);
        this.limiteSup = Math.min(this.nbTotalEntities, this.limiteInf + this.pageSize - 1);
    }

    protected generateFiltersWithPaging(page: number | null = null, pageSize: number | null = null ): IPagingFilter[] {
        return [
            ...this.genericFilters,
            {
                field: 'page',
                value: page == null ? this.currentPage.toString() : page.toString()
            },
            {
                field: 'pageSize',
                value: pageSize == null ? this.pageSize.toString() : pageSize.toString()
            }
        ];
    }
}
