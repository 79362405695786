import {
    HttpClient,
    HttpErrorResponse,
    HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IScanHistoryService } from '../../../shared/interface/scan-history/scan-history-interface.service';
import { IScanHistory } from '../../../shared/models/scan-history/scan-history.model';
import { GenericService } from '../generic/generic.service';
import {
    IMetricsApiResponse,
    IMetricsFilter,
} from './../../../shared/models/metrics/metrics.model';

@Injectable({
    providedIn: 'root',
})
export class ScanHistoryService
    extends GenericService<IScanHistory>
    implements IScanHistoryService
{
    constructor(
        public translate: TranslateService,
        public httpClient: HttpClient,
    ) {
        super(httpClient, 'scan-history');
    }

    /**
     * Get Metrics datas
     *
     * @param filter IMetricsFilter
     *
     * @returns Observable <IMetricsApiResponse>
     */
    public getMetrics(filter: IMetricsFilter): Observable<IMetricsApiResponse> {
        return this.getCountData(filter).pipe(
            map((response: any) => {
                return response;
            }),
        );
    }

    /**
     * Helper to create HttpParams for filtering based on dates.
     *
     * @param filter IMetricsFilter
     *
     * @return HttpParams
     */
    private createFilterParams(filter: IMetricsFilter): HttpParams {
        let params = new HttpParams();
        params = this.addIdsToParams(
            filter.brandDomainIds,
            'BrandDomainIds',
            params,
        );
        params = this.addIdsToParams(
            filter.digitalCategoryIds,
            'DigitalCategoryIds',
            params,
        );
        params = this.addIdsToParams(
            filter.productCategoryIds,
            'ProductCategoryIds',
            params,
        );
        params = this.addIdsToParams(
            filter.connectedMediaTypeIds,
            'ConnectedMediaTypeIds',
            params,
        );
        if (filter.startDate) {
            params = params.set('StartDate', filter.startDate);
        }
        if (filter.endDate) {
            params = params.set('EndDate', filter.endDate);
        }
        return params;
    }

    /**
     * Generate params for ids
     *
     * @param ids
     * @param paramName
     * @param params
     *
     * @returns HttpParams
     */
    private addIdsToParams(
        ids: number[],
        paramName: string,
        params: HttpParams,
    ): HttpParams {
        if (ids?.length > 0) {
            ids.filter((id) => id !== null && id !== undefined).forEach(
                (id) => {
                    params = params.append(paramName, id.toString());
                },
            );
        }
        return params;
    }

    /**
     * Get count for scans
     *
     * @param filter IMetricsFilter
     *
     * @returns Observable<IMetricsApiResponse>
     */
    private getCountData(
        filter: IMetricsFilter,
    ): Observable<IMetricsApiResponse> {
        const params = this.createFilterParams(filter);
        return this.httpClient
            .get<IMetricsApiResponse>(`${this.resourceUrl}`, { params })
            .pipe(
                catchError((error: HttpErrorResponse) =>
                    this.handleError(error),
                ),
            );
    }

    /**
     * Handle HTTP errors.
     *
     * @param error
     *
     * @returns Observable
     */
    private handleError(error: any): Observable<never> {
        const errorMessage = error.message
            ? error.message
            : this.translate.instant('common.errorHasOccured');
        return throwError(() => new Error(errorMessage));
    }
}
