/**
 * EN Format date
 * @constant
 */
export const DATE_EN_FORMAT = 'yyyy-MM-dd';

/*
 * Default Current page on list
 * @constant
 */
export const DEFAULT_CURRENT_PAGE = 1;

/**
 * Number of default pages
 * @constant
 */
export const DEFAULT_NUMBER_OF_PAGE = 0;

/**
 * Default TimeOutValue
 * @constant
 */
export const DEFAULT_TIMEOUT = 100;

/**
 * Default size page on list
 * @constant
 */
export const DEFAULT_SIZE_PAIRING_PAGE = 4;

/**
 * Default size page on list
 * @constant
 */
export const DEFAULT_SIZE_PAGE = 10;

/**
 * Number of elements displayed by default for the graph
 * @constant
 */
export const DEFAULT_TICK_AMOUNT = 10;

/**
 * Default URL if not set
 * @constant
 */
export const DEFAULT_URL = '/dashboard/metrics';

/**
 * File import done
 * @constant
 */
export const FILE_IMPORT_DONE = 2;

/**
 * File import error
 * @constant
 */
export const FILE_IMPORT_ERROR = 1;

/**
 * File import in progress
 * @constant
 */
export const FILE_IMPORT_IN_PROGRESS = 3;

/**
 * File import to process
 * @constant
 */
export const FILE_IMPORT_TOPROCESS = 4;

/**
 * Lower limit of pagination
 * @constant
 */
export const LIMIT_INF_PAGINATE = 1;

/**
 * Upper limit of pagination
 * @constant
 */
export const LIMIT_SUP_PAGINATE = 10;

/**
 * Max page size for export
 * @constant
 */
export const MAX_API_PAGE_SIZE = 500;

/**
 * Max api calls simultaneously
 * @constant
 */
export const MAX_CONNECTIONS_PER_SERVER = 4;

/**
 * Default URL if not set
 * @constant
 */
export const NEW_USER_URL = '/new-user';

/**
 * Unauthorized user
 * @constant
 */
export const UNAUTHORIZED = 401;
