import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IProductAttributeService } from '../../../shared/interface/product-attribute/product-attribute-interface.service';
import { IProductAttribute } from '../../../shared/models/product-attribute/product-attribute.model';
import { GenericService } from '../generic/generic.service';

import { IUpdateProductAttributes } from '../../../shared/models/product-attribute/update-product-attributes.model';
export type EntityResponseType = HttpResponse<any>;

@Injectable({
    providedIn: 'root',
})
export class ProductAttributeService extends GenericService<IProductAttribute> implements IProductAttributeService {
    constructor(httpClient: HttpClient) {
        super(httpClient, "product-attribute");
    }

    getFreeAttributes(): Observable<IProductAttribute[]> {
        let url = `${this.resourceUrl}/get-free-attributes`;
        return this.httpClient.get<IProductAttribute[]>(url);
    }

    getByTypologies(ids: number[]): Observable<IProductAttribute[]> {
        let url = `${this.resourceUrl}/get-by-typologies?`;
        for (let id of ids) {
            url += `ids=${id}&`;
        }
        return this.httpClient.get<IProductAttribute[]>(url);
    }

    updateList(attributes: IUpdateProductAttributes): Observable<HttpResponse<IUpdateProductAttributes>> {
        const headers = { 'Content-Type': 'application/json' };
        return this.httpClient
            .put<IUpdateProductAttributes>(`${this.resourceUrl}/update-attributes`, attributes, {
                headers: headers,
                observe: 'response',
            })
            .pipe(
                map((response: HttpResponse<IUpdateProductAttributes>) => {
                    return response;
                })
            );
    }

}