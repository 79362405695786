import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IFlaskService } from '../../../shared/interface/flask/flask-interface.service';
import { IFlask } from '../../../shared/models/flask/flask.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
    providedIn: 'root',
})
export class FlaskService extends GenericService<IFlask> implements IFlaskService {
    constructor(httpClient: HttpClient) {
        super(httpClient, "flask");
    }
}