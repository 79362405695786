import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBrandAggregationService } from '../../../shared/interface/brand-aggregation/brand-aggregation-interface.service';
import { IBrandAggregation } from '../../../shared/models/brand-aggregation/brand-aggregation.model';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;

@Injectable({
  providedIn: 'root',
})
export class BrandAggregationService extends GenericService<IBrandAggregation> implements IBrandAggregationService {
    constructor(httpClient: HttpClient) {
        super(httpClient, "brand-aggregation");
    }
}