import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IConnectedMedia } from 'src/app/shared/models/connected-media/connected-media.model';
import { IIndicator } from 'src/app/shared/models/connected-media/indicator.model';
import { IPairingBlock } from 'src/app/shared/models/connected-media/pairing-block.model';
import { IPagingFilter } from 'src/app/shared/models/helper/paging-filter.model';
import { IConnectedMediaService } from '../../../shared/interface/connected-media/connected-media-interface.service';
import { GenericService } from '../generic/generic.service';

export type EntityResponseType = HttpResponse<any>;
@Injectable({
  providedIn: 'root',
})
export class ConnectedMediaService extends GenericService<IConnectedMedia> implements IConnectedMediaService {
  
  constructor(httpClient: HttpClient) {
    super(httpClient, "connected-media");
  }

  getProductCloudUrl(digitalId: string): Observable<HttpResponse<any>> {
    return this.httpClient.get<any>(`${this.resourceUrl}/${digitalId}/product-cloud-url`);
  }

  getDetailFromDigitalId(digitalId: string): Observable<IConnectedMedia> {
    return this.httpClient.get<IConnectedMedia>(`${this.resourceUrl}/get-from-digital-id/${digitalId}`);
  }

  getDetailFromPhysicalUrl(physicalUrl: string): Observable<IConnectedMedia> {
    let filterParams: HttpParams = new HttpParams();
    filterParams = filterParams.set("physicalUrl", physicalUrl);

    return this.httpClient.get<IConnectedMedia>(`${this.resourceUrl}/physical-url`, {
      params: filterParams,
    });
  }

  getPagingToPublish(filters: IPagingFilter[], ids: number[]): Observable<IConnectedMedia[]> {
    let filterParams: HttpParams = new HttpParams();
    filters.forEach(element => {
      filterParams = filterParams.set(element.field, element.value);
    });
    let url = `${this.resourceUrl}/publish?`;
    for (let id of ids) {
      url += `ids=${id}&`;
    }
    return this.httpClient.get<IConnectedMedia[]>(url, {
      params: filterParams,
    });
  }

  getBusinessIndicators(): Observable<IIndicator[]> {
    return this.httpClient.get<IIndicator[]>(`${this.resourceUrl}/get-business-indicators`);
  }

  pairing(pairingBlock: IPairingBlock): Observable<HttpResponse<any>> {
    const headers = { 'Content-Type': 'application/json' };
    return this.httpClient.put<IPairingBlock>(`${this.resourceUrl}/pairing`, pairingBlock, {
      headers: headers,
      observe: 'response',
    })
      .pipe(
        map((response: HttpResponse<IPairingBlock>) => {
          return response;
        })
      );
  }

  redirection(digitalId: string, ip: string): Observable<string> {
    return this.httpClient.get<string>(`${this.resourceUrl}/redirect/${digitalId}/${ip}`);
  }

  publishFromId(value: number, entityId: number): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this.httpClient
      .put<any>(`${this.resourceUrl}/${entityId}`, value, {
        headers: headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          return response;
        })
      );
  }

  PublishFromFilters(filters: IPagingFilter[]): Observable<HttpResponse<any>> {
    const headers = { 'Content-Type': 'application/json' };
    return this.httpClient
      .put<any>(`${this.resourceUrl}/Publish-from-filters`, filters, {
        headers: headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          return response;
        })
      );
  }

  updateCategoryFromFilters(newCategoryId: number, filters: IPagingFilter[]): Observable<HttpResponse<any>> {
    const headers = { 'Content-Type': 'application/json' };
    return this.httpClient
      .put<any>(`${this.resourceUrl}/category?newCategoryId=${newCategoryId}`, filters, {
        headers: headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          return response;
        })
      );
  }

  // Modifiez le type de retour pour correspondre à celui défini dans l'interface
  getConnectedMediasPublish(filters: IPagingFilter[]): Observable<HttpResponse<IConnectedMedia[]>> {
    let httpParams = new HttpParams();
    filters.forEach(filter => {
      if (filter.value) {
        httpParams = httpParams.append(filter.field, filter.value);
      }
    });

    return this.httpClient.get<IConnectedMedia[]>(`${this.resourceUrl}/publish`, {
      params: httpParams,
      observe: 'response',
    });
  }

  updatePublishStatusFromId(value: boolean, conMediaId: number): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this.httpClient
      .put<any>(`${this.resourceUrl}/publish-from-id/${conMediaId}`, value, {
        headers: headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          return response;
        })
      );
  }

  updatePublishStatusFromFilters(filters: any): Observable<HttpResponse<any>> {
    const headers = { 'Content-Type': 'application/json' };
    return this.httpClient
      .put<any>(`${this.resourceUrl}/Publish-from-filters`, filters, {
        headers: headers,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          return response;
        })
      );
  }

}